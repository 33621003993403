import React from "react";
import "./App.css";
import { Authentication } from "./Authentication";
import { Character } from "./character/Character";

function App() {
  return (
    <div className="App">
      <Authentication>
        <Character />
      </Authentication>
    </div>
  );
}

export default App;
