import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth } from "./firebase";
import { GoogleAuthProvider, signInWithRedirect, User } from "firebase/auth";
import { CircularProgress } from "@mui/joy";

if (typeof window !== "undefined") {
  auth.onAuthStateChanged((user) => {
    if (!user) {
      signInWithRedirect(auth, new GoogleAuthProvider()).catch(console.error);
    }
  });
}

const UserContext = createContext<User>(undefined!);

export const useCurrentUser = () => useContext(UserContext);

export const Authentication: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState(auth.currentUser);
  useEffect(() => auth.onAuthStateChanged(setUser), []);

  if (user) {
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
  } else {
    return (
      <CircularProgress
        size="lg"
        sx={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
    );
  }
};
