import _ from "lodash/fp";

export type CharacterData = {
  readonly level: number;
  readonly xp: number;
  readonly currentHp: number;
  readonly maxHp: number;
  readonly tempHp: number;
  readonly synced: boolean;
};

export type CharacterAction =
  | {
      type: "add xp" | "apply hp" | "set temp hp";
      value: number;
    }
  | {
      type: "sync";
      value: CharacterData;
    };

export const addXp = (xp: number): CharacterAction => ({
  type: "add xp",
  value: xp,
});

export const applyHp = (hp: number): CharacterAction => ({
  type: "apply hp",
  value: hp,
});

export const setTempHp = (hp: number): CharacterAction => ({
  type: "set temp hp",
  value: hp,
});

export const sync = (newCharacter: CharacterData): CharacterAction => ({
  type: "sync",
  value: newCharacter,
});

function genericAction<T>(
  computePatch: (character: CharacterData, input: T) => Partial<CharacterData>,
): (character: CharacterData | null, input: T) => CharacterData | null {
  return (character: CharacterData | null, input: T) => {
    if (!character) {
      return null;
    }

    const newCharacter = _.merge(character, computePatch(character, input));

    if (_.equals(character, newCharacter)) {
      return character;
    } else {
      return { ...newCharacter, synced: false };
    }
  };
}

const addXpAction = genericAction((character: CharacterData, xp: number) => ({
  xp: (character.xp + xp) % 1000,
  level: Math.min(
    character.level + Math.max(0, Math.floor((character.xp + 300) / 1000)),
    20,
  ),
}));

const applyHpAction = genericAction((character: CharacterData, hp: number) => ({
  currentHp: Math.max(
    0,
    Math.min(
      character.currentHp + Math.max(Math.min(0, character.tempHp + hp), hp),
      character.maxHp,
    ),
  ),
  tempHp: Math.max(0, Math.min(character.tempHp, character.tempHp + hp)),
}));

const setTempHpAction = genericAction(
  (character: CharacterData, hp: number) => ({
    tempHp: Math.max(character.tempHp, hp),
  }),
);

const syncAction = (
  character: CharacterData | null,
  newCharacter: CharacterData,
) => {
  if (_.equals(character, newCharacter)) {
    return character;
  } else {
    return newCharacter;
  }
};

export const reducer = (
  state: CharacterData | null,
  action: CharacterAction,
) => {
  switch (action.type) {
    case "add xp":
      return addXpAction(state, action.value);
    case "apply hp":
      return applyHpAction(state, action.value);
    case "set temp hp":
      return setTempHpAction(state, action.value);
    case "sync":
      return syncAction(state, action.value);
  }
};
