import React, { useEffect, useMemo, useReducer } from "react";
import { Box, Button, CircularProgress, Input, Typography } from "@mui/joy";
import {
  addXp,
  applyHp,
  CharacterData,
  reducer,
  setTempHp,
  sync,
} from "./state";
import { useCurrentUser } from "../Authentication";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export const Character: React.FC = () => {
  const user = useCurrentUser();
  const reference = useMemo(() => doc(firestore, "users", user.uid), [user]);
  const [character, updateCharacter] = useReducer(reducer, null);

  useEffect(
    () =>
      onSnapshot(reference, (snapshot) => {
        if (snapshot.exists()) {
          updateCharacter(
            sync({ ...(snapshot.data() as CharacterData), synced: true }),
          );
        } else {
          updateCharacter(
            sync({
              level: 1,
              xp: 0,
              currentHp: 10,
              maxHp: 10,
              tempHp: 0,
              synced: false,
            }),
          );
        }
      }),
    [reference],
  );

  useEffect(() => {
    if (character) {
      const { synced, ...newState } = character;
      if (!synced) {
        setDoc(reference, newState).catch(console.error);
      }
    }
  }, [reference, character]);

  if (!character) {
    return <CircularProgress />;
  }

  const xp = `Level ${character.level} ${
    character.level < 20 ? `(${character.xp} XP)` : ""
  }`;
  const hp = `${character.currentHp} ${
    character.tempHp > 0 ? "+" + character.tempHp : ""
  } / ${character.maxHp} HP`;

  return (
    <>
      <Typography level="h2">Tech Demo</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Input size="lg" value={xp} />
          <Button onClick={() => updateCharacter(addXp(300))}>Add 300</Button>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Input size="lg" value={hp} />
          <Button color="success" onClick={() => updateCharacter(applyHp(5))}>
            Heal 5
          </Button>
          <Button color="danger" onClick={() => updateCharacter(applyHp(-4))}>
            Harm 4
          </Button>
          <Button color="neutral" onClick={() => updateCharacter(setTempHp(7))}>
            Set Temp to 7
          </Button>
        </Box>
      </Box>
    </>
  );
};
