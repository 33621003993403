import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAsb_u0dz2GJ8THlJSx_Rn_Rjcp2mbnHHA",
  authDomain: "pathfinder.scholl.dev",
  projectId: "scholl-pf2e",
  storageBucket: "scholl-pf2e.appspot.com",
  messagingSenderId: "629430285885",
  appId: "1:629430285885:web:81f61cb05660d92fd81c54",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
